import { DailyBalance } from "../components/Clicker/DailyBalance.tsx";
import { ClickerGameCanvas } from "../components/Clicker/GameCanvas.tsx";
import { useLockAppSwipe } from "../hooks/useLockAppSwipe.ts";
import { useEffect, useRef } from "react";
import { debounce } from "lodash-es";

export default function ClickerPage() {
  const { lock, unlock } = useLockAppSwipe();

  const isLockedRef = useRef(false);
  const gameAreaRef = useRef<HTMLDivElement | null>(null);

  const debouncedUnlock = useRef(
    debounce(() => {
      unlock();
      isLockedRef.current = false;
    }, 300),
  ).current;

  const handleTouchStart = () => {
    if (!isLockedRef.current) {
      lock();
      isLockedRef.current = true;
    }
    debouncedUnlock.cancel();
  };

  const handleTouchMove = (event: TouchEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!gameAreaRef.current) return;
    gameAreaRef.current.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      if (!gameAreaRef.current) return;
      gameAreaRef.current.removeEventListener("touchmove", handleTouchMove);
      debouncedUnlock.cancel();
    };
  }, []);

  return (
    <div className="flex-1 flex-col flex">
      <div className="shrink-0 text-center">
        <DailyBalance />
      </div>

      <div ref={gameAreaRef} className="grow flex" onTouchStart={handleTouchStart} onTouchEnd={debouncedUnlock}>
        <ClickerGameCanvas />
      </div>
    </div>
  );
}
