import React, { useRef } from "react";

export function useMultipleClick(
  onClick: (event: React.PointerEvent<HTMLElement>) => void | Promise<void>,
  onPressUp?: (event: React.PointerEvent<HTMLElement>) => void,
) {
  const activePointers = useRef(new Map<number, HTMLElement>());

  const handlePointerDown = async (event: React.PointerEvent<HTMLElement>) => {
    onClick(event);
  };

  const handlePointerUp = (event: React.PointerEvent<HTMLElement>) => {
    onPressUp?.(event);
    activePointers.current.delete(event.pointerId);
  };

  return {
    onPointerDown: handlePointerDown,
    onPointerUp: handlePointerUp,
    onPointerCancel: handlePointerUp,
  };
}
