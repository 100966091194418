import { useRef, ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import closeIcon from "../../assets/icons/close.svg";
import { GradientLine } from "./GradientLine.tsx";

enum ToastNotificationPositions {
  // TODO: Implement the rest of the positions
  Bottom = "bottom",
}

interface ToastNotificationProps {
  isOpened: boolean;
  onClose: () => void;
  position?: ToastNotificationPositions;
  children: ReactNode;
  duration?: number;
}

const animations = {
  [ToastNotificationPositions.Bottom]: {
    initial: { y: 100, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 17,
        duration: 0.5,
      },
    },
    exit: {
      y: 100,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 17,
        duration: 0.5,
      },
    },
  },
} satisfies Record<ToastNotificationPositions, Record<string, unknown>>;

const positionClassNames = {
  [ToastNotificationPositions.Bottom]: "bottom-4 left-4 right-4 w-[calc(100%-2rem)]",
} satisfies Record<ToastNotificationPositions, string>;

export function ToastNotification({
  isOpened,
  children,
  duration,
  onClose,
  position = ToastNotificationPositions.Bottom,
}: ToastNotificationProps) {
  const rootElement = useRef(document.getElementById("root")!);

  useEffect(() => {
    if (isOpened && duration) {
      const timeout = setTimeout(() => {
        onClose();
      }, duration);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isOpened, duration]);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpened ? (
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={animations[position]}
          className={`z-[9999999999999] toast fixed ${positionClassNames[position]} bg-[#1F1F1F] bg-opacity-75 rounded-2xl py-[14px] px-4 backdrop-blur-[32px] flex items-center`}
        >
          {/*Notification content*/}
          <div className="grow">{children}</div>

          {/*Button*/}
          <div className="shrink-0 pl-[14px] relative">
            <GradientLine className="absolute left-0 top-0 h-[24px] bg-white w-[1px]" />
            <button type="button" className="w-4 h-4 p-[1px]" aria-label="close" onClick={onClose}>
              <img src={closeIcon} className="w-full h-full object-cover" alt="Close" />
            </button>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>,
    rootElement.current,
  );
}
