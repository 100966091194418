import React, { useMemo, useRef } from "react";
import { useElementBounding } from "../../hooks/useElementBounding.ts";
import { clamp, debounce } from "lodash-es";
import { registerClickAction } from "../../store/account/actions/registerClick.ts";
import { useTelegramApp } from "../../hooks/useTelegramApp.ts";
import { useAppDispatch } from "../../store/hooks.ts";
import ball from "../../assets/img/ball.png";
import { useStore } from "react-redux";
import { RootState } from "../../store/store.ts";
import { useMultipleClick } from "../../hooks/useMultipleClick.ts";
import { useClickerProgressSave } from "../../hooks/useClickerProgressSave.ts";

const CANVAS_SIZE_PERCENT = 57.8;
const MIN_BALL_SIZE = 200;
const MAX_BALL_SIZE = 245;

const randomOffset = () => Math.random() * 40;

export function ClickerGameCanvas() {
  const telegramApp = useTelegramApp();
  const dispatch = useAppDispatch();

  const store = useStore<RootState>();
  const saveProgress = useClickerProgressSave();

  const gameCanvas = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const numbersWrapperRef = useRef<HTMLSpanElement | null>(null);

  const { height: gameCanvasHeight } = useElementBounding(gameCanvas);
  const { y: ballTopOffset, x: ballLeftOffset, width: ballWidth, height: ballHeight } = useElementBounding(buttonRef);

  const ballSize = useMemo(
    () => clamp((CANVAS_SIZE_PERCENT / 100) * gameCanvasHeight, MIN_BALL_SIZE, MAX_BALL_SIZE),
    [gameCanvasHeight],
  );

  const multipleClickHandlers = useMultipleClick(
    async (event) => {
      const result = await dispatch(registerClickAction());
      const isRejected = registerClickAction.rejected.match(result);

      if (isRejected) {
        telegramApp.HapticFeedback.impactOccurred("heavy");
        // circleCountRef.current?.applyCircle("fail");
        return;
      }

      saveProgress.current();
      appendNumber(calculateClickPositionRelativeToButton(event));
      telegramApp.HapticFeedback.impactOccurred("light");
      clicksCount.current += 1;
    },
    (event) => handleClickDeformation(event),
  );

  const clicksCount = useRef(0);
  const clearClicksDebounced = useRef(debounce(() => (clicksCount.current = 0), 300));

  function handleClickDeformation({ clientY, clientX }: React.PointerEvent<HTMLElement>) {
    if (!imageRef.current) return;

    const x = clientX - ballLeftOffset; // x position within the element
    const y = clientY - ballTopOffset; // y position within the element

    const centerX = ballWidth / 2;
    const centerY = ballHeight / 2;

    // Calculate the rotation angles based on the click position
    const rotateX = ((centerY - y) / centerY) * 8; // Rotate around X-axis
    const rotateY = ((x - centerX) / centerX) * 8; // Rotate around Y-axis

    // Calculate the scale based on the number of clicks
    const scale = clamp(1 + clicksCount.current * 0.007, 1, 1.05);

    // Apply the transformation
    imageRef.current.style.transform = `perspective(500px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(${scale})`;
    clearClicksDebounced.current();

    setTimeout(() => {
      if (!imageRef.current) return;
      imageRef.current.style.transform = `perspective(500px) rotateX(0deg) rotateY(0deg) scale(1)`;
    }, 100);
  }

  function calculateClickPositionRelativeToButton<Element extends HTMLElement>(event: React.PointerEvent<Element>) {
    const left = event.clientX - ballLeftOffset;
    const top = event.clientY - ballTopOffset;

    return { left, top };
  }

  function appendNumber(position: { left: number; top: number }) {
    const incrementCount = store.getState().account.clickCost.toString();

    const element = document.createElement("span");

    const top = clamp(position.top, 0, ballHeight);
    const left = clamp(position.left + randomOffset(), 0, ballWidth);

    element.textContent = `+${incrementCount}`;
    element.style.position = "absolute";

    element.style.top = `${top}px`;
    element.style.left = `${left}px`;

    element.style.textShadow = "0 0 10px rgba(0,0,0,0.4)";
    element.style.animation = "numberScale 1.3s linear forwards";

    const handleAnimationEnd = () => {
      element.removeEventListener("animationend", handleAnimationEnd);
      element.remove();
    };

    element.addEventListener("animationend", handleAnimationEnd);

    numbersWrapperRef.current?.appendChild(element);
  }

  return (
    <div ref={gameCanvas} className="grow flex select-none py-14">
      <div className="flex-1 flex justify-center items-center">
        <button
          tabIndex={0}
          ref={buttonRef}
          type="button"
          className={`
              rounded-full 
              relative 
              isolate
              z-10
              
            `}
          style={{
            width: ballSize,
            height: ballSize,
          }}
          {...multipleClickHandlers}
        >
          <img
            ref={imageRef}
            src={ball}
            alt="ball"
            style={{
              transformStyle: "preserve-3d",
              transition: "transform 180ms",
            }}
            className="size-full relative object-contain object-center z-20 will-change-transform"
          />
          <span
            ref={numbersWrapperRef}
            className="block z-20 pointer-events-none text-[48px] font-black size-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </button>
      </div>
    </div>
  );
}
