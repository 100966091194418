import { useEffect } from "react";
import { useTgChatId } from "./useTgChatId.ts";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants/auth.ts";
import { login } from "../api/auth/login.ts";
import { useTelegramApp } from "./useTelegramApp.ts";

export enum AuthErrorReason {
  NO_CHAT_ID = "NO_CHAT_ID",
  UNKNOWN = "UNKNOWN",
}

interface UseAuthInput {
  onLoginSuccess?: () => void;
  onLoginError?: (reason: AuthErrorReason) => void;
}

export function useAuth({ onLoginSuccess, onLoginError }: UseAuthInput) {
  const { chatId } = useTgChatId();
  const { initDataUnsafe } = useTelegramApp();

  useEffect(() => {
    // const hasAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
    //
    // if (hasAccessToken) {
    //     return onLoginSuccess?.()
    // }

    if (!chatId) {
      onLoginError?.(AuthErrorReason.NO_CHAT_ID);
      return;
    }

    loginWrapper(chatId);

    async function loginWrapper(chatId: number) {
      try {
        const { token, refreshToken } = await login(chatId, initDataUnsafe);

        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
        localStorage.setItem(ACCESS_TOKEN_KEY, token);

        onLoginSuccess?.();
      } catch (err) {
        onLoginError?.(AuthErrorReason.UNKNOWN);
      }
    }
  }, [chatId]);
}
