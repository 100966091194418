import { ReactNode } from "react";
import { Heading } from "./Heading.tsx";

export function MainLayout({ children }: { children: ReactNode }) {
  return (
    <div className="relative flex flex-col select-none min-h-screen pb-[92px]">
      <div className="container shrink-0 text-center flex items-center justify-center">
        <Heading />
      </div>
      <div className="grow flex [&>*]:flex-1">{children}</div>
    </div>
  );
}
