import { useSelector } from "react-redux";
import { Button } from "../ui/Button.tsx";
import { balanceSelector } from "../../store/account/selectors.ts";

export function DailyBalance() {
  const balance = useSelector(balanceSelector);
  const currentProgress = balance?.currentProgress ?? 0;
  const maxProgress = balance?.maxDailyProgress ?? 0;

  const canBeMaxBet = currentProgress > 0;

  function handleMaxBet() {
    if (!canBeMaxBet) return;

    // TODO: Implement max bet logic
  }

  function handleOpenBoosts() {
    // TODO: Implement opening boosts
  }

  return (
    <div>
      <strong className="text-display1 font-black italic">
        <span className={currentProgress > 0 ? "text-passive-highlighted" : ""}>{currentProgress}</span> / {maxProgress}
      </strong>

      <button type="button" className="block text-center w-full underline mt-1" onClick={handleOpenBoosts}>
        Boost Limit
      </button>

      <Button disabled={!canBeMaxBet} text="Max Bet" className="mx-auto mt-4" onClick={handleMaxBet} />
    </div>
  );
}
