import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ToastNotificationType {
  Success = "success",
  Error = "error",
  Info = "info",
}

export type ToastNotification = {
  id: number;
  title: string;
  htmlContent?: string;
  type: ToastNotificationType;
  timeoutMs?: number;
};

interface ToastNotificationState {
  notifications: ToastNotification[];
}

const initialState: ToastNotificationState = {
  notifications: [],
};

const toastNotificationSlice = createSlice({
  name: "toastNotifications",
  initialState,
  reducers: {
    showNotification(state, action: PayloadAction<ToastNotification>) {
      state.notifications.push(action.payload);
    },

    hideNotification(state, action: PayloadAction<ToastNotification["id"]>) {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { showNotification, hideNotification } = toastNotificationSlice.actions;

export default toastNotificationSlice.reducer;
