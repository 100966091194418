import { useRef } from "react";
import { debounce } from "lodash-es";
import { useAppDispatch } from "../store/hooks.ts";
import { saveProgressAction } from "../store/account/actions/saveProgress.ts";

export function useClickerProgressSave() {
  const dispatch = useAppDispatch();

  return useRef(
    debounce(
      () => {
        dispatch(saveProgressAction());
      },
      500,
      { maxWait: 25000 },
    ),
  );
}
