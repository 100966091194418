import { balanceSelector, userSelector } from "../../store/account/selectors.ts";
import { useSelector } from "react-redux";
import { ExternalImage } from "../ui/ExternalImage.tsx";
import defaultAvatar from "./../../assets/img/default-avatar.png";
import pig from "./../../assets/img/pig.png";

import { useTelegramApp } from "../../hooks/useTelegramApp.ts";
export function Heading() {
  const user = useSelector(userSelector);
  const balance = useSelector(balanceSelector);
  const {
    initDataUnsafe: { user: telegramUser },
  } = useTelegramApp();

  let userName = "User";

  if (user?.firstName) {
    userName = `${user.firstName} ${user.lastName || ""}`;
  } else if (telegramUser?.first_name) {
    userName = `${telegramUser.first_name} ${telegramUser.last_name || ""}`;
  }

  return (
    <header className="flex flex-1 justify-between px-3 pt-4 pb-6 gap-x-7">
      <div className="flex gap-x-2 items-center grow">
        <div className="size-11 shrink-0 rounded-full overflow-hidden [&>img]:size-full [&>img]:object-cover [&>img]:object-center">
          {user?.avatar ? (
            <ExternalImage src={user.avatar} alt="User avatar" />
          ) : (
            <img src={defaultAvatar} alt="Person" />
          )}
        </div>

        <span className="max-w-full break-words text-left grow">{userName} </span>
      </div>

      <div className="flex items-center gap-x-2 shrink-0">
        <span className="text-right">
          Piggy Bank <br />
          <strong>{balance?.balanceFP ?? 0}FP</strong>
        </span>

        <div className="size-11 [&>img]:size-full [&>img]:object-contain [&>img]:object-center">
          <img src={pig} alt="Pig" />
        </div>
      </div>
    </header>
  );
}
