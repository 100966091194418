import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store.ts";
import { ClickRejectErrorTypes } from "../types.ts";

export const registerClickAction = createAsyncThunk("clicker/registerClick", (_, { getState }) => {
  const {
    account: { balance, clickCost },
  } = getState() as RootState;

  if (!balance) {
    throw new Error(ClickRejectErrorTypes.DailyBalanceExceeded);
  }

  const { maxDailyProgress, currentProgress } = balance;

  const result = currentProgress + clickCost;

  if (result > maxDailyProgress) {
    throw new Error(ClickRejectErrorTypes.DailyBalanceExceeded);
  }

  return result;
});
