import App from "./App";
import ClickerPage from "./pages/ClickerPage.tsx";
import { ComingSoonPlaceholder } from "./components/ComingSoonPlaceholder.tsx";
import { sentryCreateBrowserRouter } from "./hooks/useSentry.ts";
import cupIcon from "./assets/icons/cup.png";
import ballIcon from "./assets/icons/ball.png";
import peopleIcon from "./assets/icons/people.png";
import checkmarkIcon from "./assets/icons/checkmark.png";

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: `/`,
        element: <ClickerPage />,
      },
    ],
  },
  {
    path: "/results",
    element: <ComingSoonPlaceholder title="Results" icon={cupIcon} />,
  },
  {
    path: "/matches",
    element: <ComingSoonPlaceholder title="Matches" icon={ballIcon} />,
  },

  {
    path: "/friends",
    element: <ComingSoonPlaceholder title="Friends" icon={peopleIcon} />,
  },

  {
    path: "/Tasks",
    element: <ComingSoonPlaceholder title="Tasks" icon={checkmarkIcon} />,
  },
]);

export default router;
