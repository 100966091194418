import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface IconLinkProps {
  icon: {
    src: string;
    alt: string;
  };
  to: string;
  text: string;
  className?: string;
}

export function IconLink({ icon, text, to, className = "" }: IconLinkProps) {
  return (
    <Link to={to} className={twMerge(["text-sm flex items-center text-passive-primary", className])}>
      <span className="size-[1.7em] block">
        <img src={icon.src} alt={icon.alt} className="size-full object-contain object-center" loading="lazy" />
      </span>
      <span className="ml-[0.375em] font-semibold">{text}</span>
    </Link>
  );
}
