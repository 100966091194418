import { Outlet } from "react-router-dom";

// import { BackButton } from "@twa-dev/sdk/react";
// import { useAppDispatch } from "./store/hooks";
// import { useAuth } from "./hooks/useAuth.ts";
import { AppToastNotifications } from "./components/AppToastNotifications.tsx";
import { useEffect, useState } from "react";
import { useTelegramApp } from "./hooks/useTelegramApp.ts";
// import { useNavigate } from "react-router-dom";
// import { useDataLayer } from "./hooks/useDataLayer.ts";
import ReactDOM from "react-dom";
// import { init } from "@amplitude/analytics-browser";
import { MainLayout } from "./components/Layout/MainLayout.tsx";
import { BottomMenu } from "./components/Layout/BottomMenu.tsx";
import { fetchBalanceAction } from "./store/account/actions/fetchBalance.ts";
import { useAppDispatch } from "./store/hooks.ts";
import { useAuth } from "./hooks/useAuth.ts";
import { fetchUserAction } from "./store/account/actions/fetchUser.ts";
// import { useLockAppSwipe } from "./hooks/useLockAppSwipe.ts";

export default function App() {
  // const { trackEvent } = useDataLayer();
  const dispatch = useAppDispatch();
  const telegramApp = useTelegramApp();

  useEffect(() => {
    telegramApp.init();
    // init("2bdc360e55725f513eee10e7059c53c8");
  }, []);

  // const navigate = useNavigate();

  const [isAppLoading, setIsAppLoading] = useState(false);

  useAuth({
    onLoginSuccess() {
      // const { start_param, user } = telegramApp.initDataUnsafe || {};

      // if (start_param && user) {
      //   checkInvites({ start_param, user: user }).catch();
      // }

      setIsAppLoading(false);

      dispatch(fetchBalanceAction());
      dispatch(fetchUserAction());
      // dispatch(fetchPhotoAction());
    },
  });
  // const showBackButton = window.location.pathname === "/clicker/boosts";
  return (
    <>
      <MainLayout>
        {/*{showBackButton && <BackButton />}*/}
        {!isAppLoading ? (
          <div className="relative z-40 flex-1 flex flex-col">
            <div className="flex-1 flex flex-col">
              <Outlet />
              {ReactDOM.createPortal(<BottomMenu />, document.body)}
            </div>
          </div>
        ) : null}
      </MainLayout>

      <AppToastNotifications />
    </>
  );
}
