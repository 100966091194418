import { notificationsSelector } from "../store/toastNotifications/selectors.ts";
import { useSelector } from "react-redux";
import { ToastNotification } from "./ui/ToastNotification.tsx";
import {
  hideNotification,
  ToastNotification as ToastNotificationType,
  ToastNotificationType as ToastNotificationTypeEnum,
} from "../store/toastNotifications/toastNotificationsReducer.ts";
import { useAppDispatch } from "../store/hooks.ts";
import checkmarkCircleIcon from "../assets/icons/checkmark-circle.svg";

const iconTypeMapper = {
  [ToastNotificationTypeEnum.Info]: null,
  [ToastNotificationTypeEnum.Success]: checkmarkCircleIcon,
  [ToastNotificationTypeEnum.Error]: null,
};

export function AppToastNotifications() {
  const notifications = useSelector(notificationsSelector);
  const dispatch = useAppDispatch();

  function handleHideNotification(id: ToastNotificationType["id"]) {
    dispatch(hideNotification(id));
  }

  return (
    <>
      {notifications.map(({ title, htmlContent, id, type }) => (
        <ToastNotification key={id} isOpened={true} onClose={() => handleHideNotification(id)}>
          <div className="pb-1">
            <div className="flex items-center gap-x-[6px] text-[14px] font-semibold ">
              {iconTypeMapper[type] && (
                <span>
                  <img className="w-[1.14em] h-[1.14em]" src={iconTypeMapper[type]!} aria-hidden="true" alt=" " />
                </span>
              )}
              <span>{title}</span>
            </div>

            {htmlContent && <div className="mt-[6px]" dangerouslySetInnerHTML={{ __html: htmlContent }} />}
          </div>
        </ToastNotification>
      ))}
    </>
  );
}
