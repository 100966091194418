interface GradientLineProps {
  className?: string;
}

export function GradientLine({ className }: GradientLineProps) {
  return (
    <span
      className={`${className} opacity-20`}
      style={{
        background:
          "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%)",
      }}
    />
  );
}
