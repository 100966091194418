import { ButtonHTMLAttributes, useMemo } from "react";
import { twMerge } from "tailwind-merge";

export enum ButtonVariantEnum {
  Primary = "Primary",
  Secondary = "Secondary",
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariantEnum;
  text: string;
  fluid?: boolean;
}

export function Button({ variant = ButtonVariantEnum.Primary, className, text, fluid, ...attrs }: ButtonProps) {
  const variantClassNames = getVariantClassNames(variant);
  const buttonClassNames = `
    py-3 px-14 
    ${fluid ? "w-full" : ""}
    rounded-xl
    font-black
    block
    disabled:opacity-30
  `;

  const buttonStyles = useMemo(() => getVariantStyles(variant), [variant]);

  return (
    <button {...attrs} className={twMerge([buttonClassNames, variantClassNames, className])} style={buttonStyles}>
      {text}
    </button>
  );
}

function getVariantStyles(variant: ButtonVariantEnum) {
  switch (variant) {
    case ButtonVariantEnum.Primary:
      return {
        background: "linear-gradient(135deg, #52FF00 0%, #143DDA 100%)",
      };

    case ButtonVariantEnum.Secondary:
      return {};

    default:
      const _exhaustiveCheck: never = variant;
      return _exhaustiveCheck;
  }
}

function getVariantClassNames(variant: ButtonVariantEnum) {
  switch (variant) {
    case ButtonVariantEnum.Primary:
      return "";

    case ButtonVariantEnum.Secondary:
      return "bg-action-secondary text-passive-primary";

    default:
      const _exhaustiveCheck: never = variant;
      return _exhaustiveCheck;
  }
}
