import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import WebApp from "@twa-dev/sdk";

export enum TelegramEventTypes {
  openTgLink = "web_app_open_link",
}

const APP_BACKGROUND = "#000000";

export function useTelegramApp() {
  const telegramApp = WebApp;

  useEffect(() => {
    const user = telegramApp.initDataUnsafe.user;
    if (!user) {
      Sentry.captureException("TG: No user data");
    }
  }, []);

  return {
    ...telegramApp,
    init() {
      try {
        telegramApp.ready();
        telegramApp.setBackgroundColor(APP_BACKGROUND);
        telegramApp.setHeaderColor(APP_BACKGROUND);
        telegramApp.expand();
      } catch (err) {
        Sentry.setTags({
          telegramError: "init error",
        });

        Sentry.captureException(err);
      }
    },
  };
}
