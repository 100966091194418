import { NavLink } from "react-router-dom";
import cupIcon from "../../assets/icons/cup.png";
import ballIcon from "../../assets/icons/ball.png";
import kickIcon from "../../assets/icons/kick.png";
import peopleIcon from "../../assets/icons/people.png";
import checkmarkIcon from "../../assets/icons/checkmark.png";
import { IconLink } from "../ui/IconLink.tsx";
import rocket from "../../assets/icons/rocket.png";

const menuItems = [
  { name: "Results", icon: cupIcon, to: "/results" },
  { name: "Matches", icon: ballIcon, to: "/matches" },
  { name: "Mine", icon: kickIcon, to: "/" },
  { name: "Friends", icon: peopleIcon, to: "/friends" },
  { name: "Tasks", icon: checkmarkIcon, to: "/tasks" },
];

export function BottomMenu() {
  return (
    <div className="fixed bottom-0 w-full isolate z-50">
      <div className="shrink-0 container relative z-10">
        <div className="flex justify-between px-1">
          <IconLink icon={{ src: rocket, alt: "Rocket" }} to="/hot-to-play" text="How to play" />
          <IconLink icon={{ src: rocket, alt: "Rocket" }} to="/boosts" text="Boosts" />
        </div>
      </div>

      <nav className="z-10 w-full relative container z pb-7 pt-5">
        <ul className="flex gap-0 bg-passive-primary text-[11px] [&>*>*:not(.active)]:text-passive-secondary font-medium bg-opacity-[0.15] backdrop-blur-10 rounded-2xl px-2">
          {menuItems.map((item, index, array) => (
            <li key={item.to} className="w-1/5">
              <NavLink className="py-3 block" to={item.to}>
                <span
                  className={`
                    flex flex-col items-center text-center 
                    ${index < array.length - 1 ? "px-4 border-r-passive-primary/10 border-r" : ""}
                  `}
                >
                  <span className="block w-4 h-[18px] mb-2">
                    <img className="size-full object-contain object-center" src={item.icon} alt={item.name} />
                  </span>

                  <span className="leading-none">{item.name}</span>
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      {/*GRADIENT*/}
      <span
        className="absolute z-0 w-full bottom-0 h-[25vh] min-h-[180px]"
        style={{
          background: `
            linear-gradient(
              to top,
              rgba(0, 0, 0, 1) 73%,
              rgba(0, 0, 0, 0) 100%
            )
          `,
        }}
      />
    </div>
  );
}
