export const apiRoutes = {
  auth: {
    login: "/auth",
    refreshToken: "/refresh-token",
  },
  account: {
    getUser: "/users",
    getBalance: "/user-quests/progress-with-balance",
    saveProgress: "/user-quests/update-progress",
  },
};
