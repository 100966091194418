import { GradientLine } from "./ui/GradientLine.tsx";
import CloseIcon from "../assets/icons/close.svg";
import { NavLink } from "react-router-dom";

export function ComingSoonPlaceholder({ title, icon }: { title: string; icon: string }) {
  return (
    <div className="flex-1 container flex items-center">
      <div className="w-full h-[72px] bg-[#191919] flex z-40 px-4 py-[18px] rounded-2xl  justify-between items-center ">
        <div className="flex flex-col">
          <p className="flex items-center">
            <img src={icon} alt="lobby" className="w-[16px] object-contain h-5 pr-1 shrink-0" />
            <span className="font-semibold grow text-sm">{title}</span>
          </p>
          <p className="text-[#989898] text-xs">Coming soon</p>
        </div>

        <div>
          <GradientLine className="absolute right-[58px] top-1/2 -translate-y-1/2 h-[24px] w-[1px]" />
          <NavLink to="/">
            <img src={CloseIcon} alt="close" className="w-4 h-4" />
          </NavLink>
        </div>
      </div>
      <div
        className="absolute top-0 left-[-16px] right-[-16px] bottom-0 bg-black opacity-[94%]"
        style={{ backdropFilter: "blur(16px)" }}
      ></div>
    </div>
  );
}
