import { MutableRefObject, useEffect, useRef, useState } from "react";
export function useElementBounding<Element extends HTMLElement>(target: MutableRefObject<Element | null>) {
  const [bounding, setBounding] = useState(() => ({
    width: 0,
    height: 0,
    y: 0,
    x: 0,
  }));

  const observer = useRef(
    new ResizeObserver(() => {
      setBounding(target.current!.getBoundingClientRect());
    }),
  );

  useEffect(() => {
    if (target.current) {
      setBounding(target.current.getBoundingClientRect());
      observer.current.observe(target.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return bounding;
}
