import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveClickerProgress } from "../../../api/account/saveClickerProgress.ts";
import { RootState } from "../../store.ts";

export const saveProgressAction = createAsyncThunk("account/saveProgress", async (_, { getState }) => {
  const currentProgress = (getState() as RootState).account.balance?.currentProgress;

  if (!currentProgress) {
    throw new Error("No progress to save");
  }

  return saveClickerProgress(currentProgress);
});
