import { useTelegramApp } from "./useTelegramApp.ts";
import { useEffect, useState } from "react";

export function useTgChatId() {
  const webApp = useTelegramApp();
  const [chatId, setChatId] = useState<number | null>(null);

  const env = import.meta.env;

  useEffect(() => {
    if (env.VITE_TEST_TG_CHAT_ID) {
      return setChatId(env.VITE_TEST_TG_CHAT_ID ? parseInt(env.VITE_TEST_TG_CHAT_ID) : null);
    }

    if (!webApp.initDataUnsafe.user?.id) {
      return;
    }

    setChatId(webApp.initDataUnsafe.user.id);
  }, []);

  return {
    chatId,
  };
}
