import { createSlice } from "@reduxjs/toolkit";
import { fetchBalanceAction } from "./actions/fetchBalance.ts";
import { Balance, User } from "../../api/account/types.ts";
import { fetchUserAction } from "./actions/fetchUser.ts";
import { registerClickAction } from "./actions/registerClick.ts";

interface AccountState {
  pending: boolean;
  error: string | null;
  user: User | null;
  balance: Balance | null;
  clickCost: number;
}

const initialState: AccountState = {
  user: null,
  balance: null,
  pending: false,
  error: null,
  clickCost: 1,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // User
    builder.addCase(fetchUserAction.pending, (state) => {
      state.pending = true;
      state.error = null;
    });

    builder.addCase(fetchUserAction.fulfilled, (state, action) => {
      state.pending = false;
      state.user = action.payload as User;
    });

    builder.addCase(fetchUserAction.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message ?? "Unknown error";
    });

    // Balance

    builder.addCase(fetchBalanceAction.pending, (state) => {
      state.pending = true;
      state.error = null;
    });

    builder.addCase(fetchBalanceAction.fulfilled, (state, action) => {
      state.pending = false;
      state.balance = action.payload;
    });

    builder.addCase(fetchBalanceAction.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message ?? "Unknown error";
    });

    // Register click
    builder.addCase(registerClickAction.fulfilled, (state, { payload }) => {
      if (state.balance) {
        state.balance.currentProgress = payload;
        state.balance.balanceBP += state.clickCost;
      }
    });
  },
});

export const accountReducer = accountSlice.reducer;
