import { configureStore } from "@reduxjs/toolkit";
import toastNotificationsReducer from "./toastNotifications/toastNotificationsReducer.ts";
import * as Sentry from "@sentry/react";
import { accountReducer } from "./account/account.tsx";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    account: accountReducer,
    toastNotifications: toastNotificationsReducer,
  },

  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
